import React, { useContext } from 'react';
import AppContext from '../../context/AppContext';
import { Link } from 'react-router-dom';
import { GoChevronDown, GoArrowRight } from 'react-icons/go';

function Home() {
  const { openState } = useContext(AppContext);
  const [open, setOpen] = openState;
  return (
    <header className='App-header'>
      <h1>Aues Guete zum Geburtstag Baccem!!!🎉🎁</h1>
      <Link to='/geschenk' onClick={() => setOpen(false)}>
        <GoArrowRight size={60} className={'startButton'}></GoArrowRight>
      </Link>
    </header>
  );
}

export default Home;
