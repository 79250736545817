import React, { useContext } from 'react';
import AppContext from '../../context/AppContext';
import './index.css';

function Box() {
  const { openState } = useContext(AppContext);
  const [open, setOpen] = openState;
  return (
    <div className='giftBox'>
      <canvas></canvas>

      <div className='present' onClick={() => setOpen(true)}>
        <div className='name'>Hektor</div>

        <div className='rotate-container'>
          <div className='bottom'></div>
          <div className='front'></div>
          <div className='left'></div>
          <div className='back'></div>
          <div className='right'></div>

          <div className='lid'>
            <div className='lid-top'></div>
            <div className='lid-front'></div>
            <div className='lid-left'></div>
            <div className='lid-back'></div>
            <div className='lid-right'></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Box;
