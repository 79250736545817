import React, { useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './App.css';
import firebase from 'firebase';
import GiftPage from './components/GiftPage';
import Home from './components/Home';
import AppContext from './context/AppContext';

function App() {
  /*
  const firebaseConfig = {
    apiKey: 'AIzaSyDVXbPgUjipYMwD8DndKcyEfFrBd6PLWCM',
    authDomain: 'pushtest-c65d8.firebaseapp.com',
    databaseURL: 'https://pushtest-c65d8.firebaseio.com',
    projectId: 'pushtest-c65d8',
    storageBucket: 'pushtest-c65d8.appspot.com',
    messagingSenderId: '144559664440',
    appId: '1:144559664440:web:f2f390a8ee976b8d8d65cd'
  };
  firebase.initializeApp(firebaseConfig);
  const db = firebase.firestore();
  */
  const [open, setOpen] = useState(false);
  const [choice, setChoice] = useState();

  return (
    <Router>
      <AppContext.Provider
        value={{ openState: [open, setOpen], choiceState: [choice, setChoice] }}
      >
        <div className='App'>
          {/* A <Switch> looks through its children <Route>s and
           renders the first one that matches the current URL. */}
          <Switch>
            <Route path='/geschenk'>
              <GiftPage></GiftPage>
            </Route>
            <Route path='/'>
              <Home />
            </Route>
          </Switch>
        </div>
      </AppContext.Provider>
    </Router>
  );
}

export default App;
