import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Item = styled.div`
  background-color: white;
  padding: 2.5rem;
  border-radius: 1rem;
  img {
    max-width: 200px;
    width: 200px;
  }
  a {
  }
`;
const Features = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  span {
    margin: 0.25rem;
    padding: 0.25rem;
    background-color: grey;
    border-radius: 0.25rem;
    color: white;
  }
`;
const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

function GiftItem(props) {
  return (
    <Item>
      <a href={props.item.url} target='_blank'>
        <img src={props.item.img}></img>
      </a>
      <Info>
        <div>
          <h2>{props.item.name}</h2>
          {props.item.capacity && <p>Kapazität: {props.item.capacity} Kg</p>}
          {props.item.rpm && <p>Umdrehungen: {props.item.rpm} U/min</p>}
          {props.item.u && <p>Anschluss: {props.item.u} V</p>}
          {props.item.features && (
            <Features>
              {props.item.features.map(element => {
                return <span key={element}>{element}</span>;
              })}
            </Features>
          )}
        </div>
      </Info>
      {props.select && <button onClick={() => props.select()}>Wählen</button>}
    </Item>
  );
}

export default GiftItem;
