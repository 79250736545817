import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import GiftItem from '../GiftItem';
import styled from 'styled-components';
import AppContext from '../../context/AppContext';

const elements = [
  {
    id: 1,
    name: 'Miele WWR 800-60 CH',
    img: '/img/miele3.jpg',
    capacity: '9',
    rpm: '1600',
    u: '230/400',

    features: [
      'TwinDos',
      'PowerWash',
      'WiFiConn@ct',
      'MTouch',
      'Timer',
      'CapDosing'
    ],
    url:
      'https://service.loadbee.com/ean/4002516083870/de_CH?css=default&template=default&button=default&data=%7B%22shop%22%3A%22www.fust.ch%22%2C%22source%22%3A%22inpage%22%2C%22api%22%3A%22S5AYw3gC2AJPpaRszWnTmTmXD6KLrUP6%22%7D',
    buyUrl:
      'https://www.fust.ch/de/p/haushalt/waschmaschinen-und-waeschetrockner/waschmaschine-einfamilienhaus/waschmaschinen-frontlader/miele/wwr-800-60-ch-8391370.html'
  },
  {
    id: 2,
    name: 'Miele WCR 800-60 CH',
    img: '/img/miele4.jpg',
    capacity: '9',
    rpm: '1600',
    u: '230/400',

    features: [
      'TwinDos',
      'PowerWash',
      'WiFiConn@ct',
      'MTouch',
      'Timer',
      'CapDosing'
    ],
    url:
      'https://service.loadbee.com/ean/4002516083818/de_CH?css=default&template=default&button=default&data=%7B%22shop%22%3A%22www.fust.ch%22%2C%22source%22%3A%22inpage%22%2C%22api%22%3A%22S5AYw3gC2AJPpaRszWnTmTmXD6KLrUP6%22%7D',
    buyUrl:
      'https://www.fust.ch/de/p/haushalt/waschmaschinen-und-waeschetrockner/waschmaschine-einfamilienhaus/waschmaschinen-frontlader/miele/wcr-800-60-ch-8391193.html?gclid=Cj0KCQjwmpb0BRCBARIsAG7y4zaHF0dYJVZ8m4muiiB3oS_popouOgYRuYNxEnPG962UTlpcsViBDRUaAjAJEALw_wcB'
  },
  {
    id: 3,
    name: 'Miele WCR 800-90 CH',
    img: '/img/miele5.jpg',
    capacity: '9',
    rpm: '1600',
    u: '230/400',
    features: [
      'SteamCare',
      'TwinDos',
      'PowerWash',
      'WiFiConn@ct',
      'MTouch',
      'Timer',
      'CapDosing'
    ],
    url:
      'https://service.loadbee.com/ean/4002516083733/de_CH?css=default&template=default&button=default&data=%7B%22shop%22%3A%22www.fust.ch%22%2C%22source%22%3A%22inpage%22%2C%22api%22%3A%22S5AYw3gC2AJPpaRszWnTmTmXD6KLrUP6%22%7D',
    buyUrl:
      'https://www.fust.ch/de/p/haushalt/waschmaschinen-und-waeschetrockner/waschmaschine-einfamilienhaus/waschmaschinen-frontlader/miele/wcr-800-90-ch-8391186.html'
  }
  /*
    {
    id: 1,
    name: 'Miele WCR 700-70 CH',
    img: '/img/miele1.jpg',
    capacity: '9',
    rpm: '1600',
    u: '400',
    features: ['TwinDos', 'WiFiConn@ct', 'MTouch', 'Timer', 'CapDosing'],
    url:
      'https://service.loadbee.com/ean/4002516083894/de_CH?css=default&template=default&button=default&data=%7B%22shop%22%3A%22www.fust.ch%22%2C%22source%22%3A%22inpage%22%2C%22api%22%3A%22S5AYw3gC2AJPpaRszWnTmTmXD6KLrUP6%22%7D',
    buyUrl:
      'https://www.microspot.ch/de/haushalt-k%C3%BCche/waschen-b%C3%BCgeln/waschmaschinen--c673000/miele-wcr-700-70-ch-9-kg--p0001646787?gclid=Cj0KCQjw9ZzzBRCKARIsANwXaeIMzXCb9iFkFhi2qxt2P7udmES4JcIWvmzWy1wRwXXmRGUfFvT-hcAaAvooEALw_wcB&gclsrc=aw.ds'
  },
  {
    id: 2,
    name: 'Miele WCR 800-60 CH',
    img: '/img/miele2.jpg',
    capacity: '9',
    rpm: '1400',
    u: '230/400',
    features: ['Timer', 'CapDosing'],
    url:
      'https://service.loadbee.com/ean/4002516083818/de_CH?css=default&template=default&button=default&data=%7B%22shop%22%3A%22www.fust.ch%22%2C%22source%22%3A%22inpage%22%2C%22api%22%3A%22S5AYw3gC2AJPpaRszWnTmTmXD6KLrUP6%22%7D',
    buyUrl:
      'https://www.fust.ch/de/p/haushalt/waschmaschinen-und-waeschetrockner/waschmaschine-einfamilienhaus/waschmaschinen-frontlader/miele/wcr-800-60-ch-8391193.html?gclid=Cj0KCQjwmpb0BRCBARIsAG7y4zaHF0dYJVZ8m4muiiB3oS_popouOgYRuYNxEnPG962UTlpcsViBDRUaAjAJEALw_wcB'
  },
  {
    id: 3,
    name: 'Samsung WD 80M4A53JW/WS',
    img: '/img/samsung1.jpg',
    capacity: '8',
    rpm: '1400',
    u: '230',
    features: ['Airwash', 'Schaumaktiv'],
    url: 'https://www.samsung.com/ch/washing-machines/combo-wd80m4a53jw/',
    buyUrl:
      'https://www.fust.ch/de/p/haushalt/waschmaschinen-und-waeschetrockner/waschmaschine-einfamilienhaus/waschmaschinen-trockner-kombiniert/samsung/wd-80m4a53jw-ws-8374557.html?gclid=Cj0KCQjw6_vzBRCIARIsAOs54z4-YBLCT7hDJbHwtVpw5kFVQTt5vk1XHid7LXt5tLG36V28SmwSl_kaAmJFEALw_wcB'
  },
  {
    id: 4,
    name: 'LG F14WM10GT',
    img: '/img/lg1.jpg',
    capacity: '10',
    rpm: '1400',
    u: '230',
    features: ['TrueSteam', 'TurboWash', 'Add Item', 'SmartThinQ'],
    url: 'https://www.lg.com/ch_de/waschmaschinen/lg-F14WM10GT',
    buyUrl:
      'https://www.galaxus.ch/de/s2/product/lg-f14wm10gt-waschmaschine-6611090?tagIds=495-870'
  },
  {
    id: 5,
    name: 'Tesla Aktie',
    img: '/img/tesla1.png',
    //features: ['Timer', 'CapDosing'],
    buyUrl: 'google.ch',
    url:
      'https://www.google.com/search?q=tesla+aktie&sxsrf=ALeKk03X2Do9ENIwDjrNhluCiltyb_1l4g:1585681361467&source=lnms&tbm=fin&sa=X&ved=2ahUKEwi7sJuys8XoAhUhxqYKHWp5C68Q_AUoAXoECBYQAw&biw=1920&bih=1009#scso=_3ZODXv2fMPHorgSAjKnwCA1:0'
  }
  */
];

const Elements = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 2rem;
  padding: 2rem;
`;
const Iframe = styled.iframe`
  width: 100%;
  height: 100vh;
  border: none;
`;
const Footer = styled.div`
  padding: 0 2rem 2rem 2rem;
`;
const PaddingPopup = styled.div`
  padding: 2rem 2rem 2rem 2rem;
  max-width: 400px;
  margin: auto;
`;
const PopUp = styled.div`
  background-color: #efefef;
  width: 100%;
  padding: 2rem;
  box-sizing: border-box;
  border-radius: 1rem;
`;
const Error = styled.p`
  color: red;
  font-size: 1.25rem;
  margin-top: 1rem;
  margin-bottom: 0;
`;
const Input = styled.input`
  width: 100%;
  font-size: 1.5rem;
  padding: 1rem;
  box-sizing: border-box;
  text-align: center;
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
function GiftElements() {
  const { choiceState } = useContext(AppContext);
  const [choice, setChoice] = choiceState;
  const [diff, setDiff] = useState(false);
  const [value, setValue] = useState('');
  const [popUp, setPopUp] = useState(false);
  const [error, setError] = useState('');
  const pin = 3953;
  const checkPin = e => {
    e.preventDefault();
    if (parseInt(value) === pin) {
      setPopUp(false);
      setDiff(true);
      setError('');
    } else {
      setError('Falscher PIN');
    }
  };
  return (
    <>
      {!popUp && !diff && (
        <Elements>
          {elements.map(item => (
            <GiftItem
              key={item.id}
              item={item}
              select={() => setChoice(item)}
            ></GiftItem>
          ))}
        </Elements>
      )}

      <Footer>
        <button
          onClick={() => {
            if (diff || popUp) {
              setDiff(false);
              setPopUp(false);
            } else {
              setPopUp(true);
            }
          }}
        >
          {diff || popUp ? 'Vergleich schliessen' : 'Vergleich öffnen'}
        </button>
      </Footer>

      {diff && (
        <Iframe src='https://www.miele.ch/de/c/merkzettel-1940.htm?compare=11005940,11005950,11005920&tax=6'></Iframe>
      )}
      {popUp && (
        <PaddingPopup>
          <PopUp>
            <form onSubmit={e => checkPin(e)}>
              <Input
                value={value}
                placeholder='PIN'
                onChange={e => setValue(e.target.value)}
                type='number'
              ></Input>

              <Error>{error}</Error>

              <button>Öffnen</button>
            </form>
          </PopUp>
        </PaddingPopup>
      )}
    </>
  );
}

export default GiftElements;
