import React, { useContext } from 'react';
import Box from '../Box';
import GiftElements from '../GiftElements';
import GiftItem from '../GiftItem';
import AppContext from '../../context/AppContext';
import styled from 'styled-components';
const Elements = styled.div`
  display: flex;
  justify-content: center;
`;
function GiftPage() {
  const { openState } = useContext(AppContext);
  const [open] = openState;
  const { choiceState } = useContext(AppContext);
  const [choice] = choiceState;

  if (!open) {
    return <Box></Box>;
  } else if (!choice) {
    return (
      <>
        <h1>Was wett dr Bacc am liebste?</h1>
        <GiftElements></GiftElements>
      </>
    );
  } else {
    console.log(choice);
    return (
      <>
        <h1>Dr Bacc wünscht:</h1>
        <Elements>
          <GiftItem item={choice}></GiftItem>
        </Elements>
        <h2 className={'liebe'}>I liebe di über aues mi Bacc!!</h2>
        <h1>💝</h1>
      </>
    );
  }
}

export default GiftPage;
